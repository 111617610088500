import { ChangeEvent, useEffect, useState } from "react";
import Cookies from "js-cookie";
import ReactPaginate from "react-paginate";

interface QuestionData {
  id: string;
  question: string;
  answera: string;
  answerb: string;
  answerc: string;
  answerd: string;
  answere: string;
  answerf: string;
  answerg: string;
  answerh: string;
  answeradetail: string;
  answerbdetail: string;
  answercdetail: string;
  answerddetail: string;
  answeredetail: string;
  answerfdetail: string;
  answergdetail: string;
  answerhdetail: string;
  questiontype: string;
  correctanswer: string;
  disabled: boolean;
  questionweight?: string | number;
  question_weight?: string | number;
  tags?: {
    competition: string;
    [customLabel: string]: string;
  };
  answers?: {
    [key: string]: [string, boolean, string?];
  };
  answered_count?: number;
  answered_correct?: number;
  question_type?: string;
}

export const Admin = () => {
  const [loadingQuestions, setLoadingQuestions] = useState(true);
  const [questions, setQuestions] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState(questions);
  const [searchValue, setSearchValue] = useState("");
  const [questionText, setQuestionText] = useState("");
  const questionTypes = ["home", "away", "general"];
  const [editMode, setEditMode] = useState(false);
  const [editType, setEditType] = useState("create");
  const [updating, setUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(false);
  const [answerA, setAnswerA] = useState("");
  const [answerB, setAnswerB] = useState("");
  const [answerC, setAnswerC] = useState("");
  const [answerD, setAnswerD] = useState("");
  const [answerE, setAnswerE] = useState("");
  const [answerF, setAnswerF] = useState("");
  const [answerG, setAnswerG] = useState("");
  const [answerH, setAnswerH] = useState("");
  const [answerADetail, setAnswerADetail] = useState("");
  const [answerBDetail, setAnswerBDetail] = useState("");
  const [answerCDetail, setAnswerCDetail] = useState("");
  const [answerDDetail, setAnswerDDetail] = useState("");
  const [answerEDetail, setAnswerEDetail] = useState("");
  const [answerFDetail, setAnswerFDetail] = useState("");
  const [answerGDetail, setAnswerGDetail] = useState("");
  const [answerHDetail, setAnswerHDetail] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("ans1");
  const [thisQuestionId, setThisQuestionId] = useState(0);
  const [queryFilters, setQueryFilters] = useState<string>("");
  const filtersOptions = ["", "home", "away", "general"];
  const [itemOffset, setItemOffset] = useState(0);
  const [itemsPerPageState, setItemsPerPageState] = useState(10);
  const [questionWeightFilter, setQuestionWeightFilter] = useState<
    number | string
  >("");
  const [questionWeight, setQuestionWeight] = useState<number | string>("");
  const [questionTypeFilter, setQuestionTypeFilter] = useState("home");
  const [questionType, setQuestionType] = useState("home");
  const [isDisabledFilter, setIsDisabledFilter] = useState<string | boolean>(
    ""
  );
  const [customLabel, setCustomLabel] = useState("");
  const [customInput, setCustomInput] = useState("");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [competition, setCompetition] = useState<string>("");
  const [competitionList, setCompetitionList] = useState<string[]>([]);
  const [selectCompetition, setSelectCompetition] = useState<string>("");
  const [required, setRequired] = useState("");
  const [csvData, setCsvData] = useState<File | null>(null);
  const [infoMessage, setInfoMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [questionUpdateMessage, setQuestionMessage] = useState("");

  const questionFilters = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  useEffect(() => {
    function resizePage() {
      setIsMobile(window.innerWidth <= 768);
    }

    window.addEventListener("resize", resizePage);
  }, []);

  const handleFilterOfQuestions = (questions) => {
    return questions.filter((item) => {
      return Object.values(item)
        .join("")
        .toLowerCase()
        .includes(searchValue.toLowerCase());
    });
  };

  const handleSearch = (e) => {
    setSearchValue(e.target.value);
    if (!e.target.value || e.target.value === "") {
      setFilteredQuestions(questions);
    } else {
      const filteredData = handleFilterOfQuestions(questions);
      setFilteredQuestions(filteredData);
    }
  };

  async function fetchQuestions() {
    const session_token = Cookies.get("c8_session_token");
    try {
      const response = await fetch(
        `${process.env.API_URL}/admin/getquestions?user_session=${session_token}&qType=${queryFilters}&qWeight=${questionWeightFilter}&qIsDisabled=${isDisabledFilter}&qCompetition=${selectCompetition}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY as string,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setQuestions(data.questions.rows);

        const filteredData = handleFilterOfQuestions(data.questions.rows);
        setFilteredQuestions(filteredData);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingQuestions(false);
    }
  }

  async function fetchQuestionsCompetitions() {
    const session_token = Cookies.get("c8_session_token");
    try {
      const response = await fetch(
        `${process.env.API_URL}/admin/getquestionscompetitions?user_session=${session_token}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY as string,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setCompetitionList(data.competitions);
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  useEffect(() => {
    fetchQuestionsCompetitions();
    fetchQuestions();
  }, [queryFilters, questionWeightFilter, isDisabledFilter, selectCompetition]);

  function toggleUnselect(item: number) {
    setQuestionWeightFilter((prev) => (prev === item ? "" : item));
  }

  function toggleDisabled(item: boolean) {
    setIsDisabledFilter((prev) => (prev === item ? "" : item));
  }

  function clearFilters() {
    setQueryFilters("");
    setQuestionWeightFilter("");
    setIsDisabledFilter("");
    setCompetition("");
    setSelectCompetition("");
  }

  const endOffset = itemOffset + itemsPerPageState;
  const questionData = filteredQuestions.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(filteredQuestions.length / itemsPerPageState);

  function handlePageClick(e: { selected: number }) {
    const newOffset = (e.selected * itemsPerPageState) % questions.length;
    setItemOffset(newOffset);
  }

  async function uploadQuestions() {
    setLoading(true);
    setInfoMessage("File is uploading...");
    const session_token = Cookies.get("c8_session_token");
    const formData = new FormData();
    if (csvData) {
      formData.append("file", csvData);
    }
    try {
      const res = await fetch(
        `${process.env.API_URL}/admin/uploadquestions/${session_token}`,
        {
          method: "POST",
          headers: {
            "x-api-key": process.env.API_KEY as string,
          },
          body: formData,
        }
      );
      if (res.status === 200) {
        setInfoMessage("File uploaded successfully");
        setQuestionMessage("Questions added successfully");
      } else if (res.status === 500) {
        const response = res.json();
        setInfoMessage("File upload failed " + JSON.stringify(response));
      } else {
        setInfoMessage("File upload failed");
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setCsvData(null);
      setLoading(false);
      setTimeout(() => {
        setInfoMessage("");
        setQuestionMessage("");
        fetchQuestions();
        // window.location.reload();
      }, 3000);
    }
  }

  function handleFileUpload(e: ChangeEvent<HTMLInputElement>) {
    setLoading(true);
    setInfoMessage("File is attaching...");

    const file = e.target.files?.[0] || null;

    setTimeout(() => {
      setCsvData(file);
      setLoading(false);
      setInfoMessage(
        file ? "File is successfully attached!" : "Attaching failed"
      );
    }, 2000);
  }

  async function updateQuestion(questionId, disabled) {
    const session_token = Cookies.get("c8_session_token");
    const headers = {
      "Content-Type": "application/json",
    };
    headers["x-api-key"] = process.env.API_KEY;

    const body: QuestionData = {
      id: questionId,
      question: questionText,
      answera: answerA,
      answerb: answerB,
      answerc: answerC,
      answerd: answerD,
      answere: answerE,
      answerf: answerF,
      answerg: answerG,
      answerh: answerH,
      answeradetail: answerADetail,
      answerbdetail: answerBDetail,
      answercdetail: answerCDetail,
      answerddetail: answerDDetail,
      answeredetail: answerEDetail,
      answerfdetail: answerFDetail,
      answergdetail: answerGDetail,
      answerhdetail: answerHDetail,
      questiontype: questionType,
      correctanswer: correctAnswer,
      disabled: disabled,
      questionweight: questionWeight,
      tags: {
        competition: competition || "",
        [customLabel]: customInput || "",
      },
    };

    if ("" in body.tags) {
      delete body.tags[""];
    }

    if (questionWeight) {
      body.questionweight = questionWeight;
    }

    const response = await fetch(
      `${process.env.API_URL}/admin/updatequestion/${session_token}`,
      {
        method: "post",
        headers: headers,
        body: JSON.stringify(body),
      }
    );

    setUpdating(false);
    if (response.status === 200) {
      const data = response;
      setEditMode(false);
      fetchQuestions();
    } else {
      setUpdateError(true);
    }
  }

  async function changeDisabled(questionId) {
    const session_token = Cookies.get("c8_session_token");
    const headers = {
      "Content-Type": "application/json",
    };
    headers["x-api-key"] = process.env.API_KEY;
    const response = await fetch(
      `${process.env.API_URL}/admin/togglequestiondisabled/${session_token}`,
      {
        method: "post",
        headers: headers,
        body: JSON.stringify({
          id: questionId,
        }),
      }
    );
    fetchQuestions();
  }

  async function fetchQuestion(questionid) {
    const session_token = Cookies.get("c8_session_token");
    const headers = {
      "Content-Type": "application/json",
    };
    headers["x-api-key"] = process.env.API_KEY;
    try {
      const response = await fetch(
        `${process.env.API_URL}/admin/getsinglequestion/${questionid}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": process.env.API_KEY as string,
          },
        }
      );

      if (response.status === 200) {
        const data = await response.json();
        setQuestionText(data.question.question);
        setAnswerA(data.question.answers?.["a"]?.[0] ?? "");
        setAnswerB(data.question.answers?.["b"]?.[0] ?? "");
        setAnswerC(data.question.answers?.["c"]?.[0] ?? "");
        setAnswerD(data.question.answers?.["d"]?.[0] ?? "");
        setAnswerE(data.question.answers?.["e"]?.[0] ?? "");
        setAnswerF(data.question.answers?.["f"]?.[0] ?? "");
        setAnswerG(data.question.answers?.["g"]?.[0] ?? "");
        setAnswerH(data.question.answers?.["h"]?.[0] ?? "");
        setAnswerADetail(data.question.answers?.["a"]?.[2] ?? "");
        setAnswerBDetail(data.question.answers?.["b"]?.[2] ?? "");
        setAnswerCDetail(data.question.answers?.["c"]?.[2] ?? "");
        setAnswerDDetail(data.question.answers?.["d"]?.[2] ?? "");
        setAnswerEDetail(data.question.answers?.["e"]?.[2] ?? "");
        setAnswerFDetail(data.question.answers?.["f"]?.[2] ?? "");
        setAnswerGDetail(data.question.answers?.["g"]?.[2] ?? "");
        setAnswerHDetail(data.question.answers?.["h"]?.[2] ?? "");
        setCompetition(data.question.tags?.competition || "");
        setQuestionWeight(data.question.question_weight || "");

        const displayCustomTags = Object.keys(data.question.tags || {}).find(
          (tag) => !["competition"].includes(tag)
        );
        setCustomLabel(displayCustomTags || "");
        setCustomInput(
          displayCustomTags ? data.question.tags[displayCustomTags] : ""
        );

        if (data.question.answers?.["a"]?.[1]) {
          setCorrectAnswer("ans1");
        }
        if (data.question.answers?.["b"]?.[1]) {
          setCorrectAnswer("ans2");
        }
        if (data.question.answers?.["c"]?.[1]) {
          setCorrectAnswer("ans3");
        }
        if (data.question.answers?.["d"]?.[1]) {
          setCorrectAnswer("ans4");
        }
        if (data.question.answers?.["e"]?.[1]) {
          setCorrectAnswer("ans5");
        }
        if (data.question.answers?.["f"]?.[1]) {
          setCorrectAnswer("ans6");
        }
        if (data.question.answers?.["g"]?.[1]) {
          setCorrectAnswer("ans7");
        }
        if (data.question.answers?.["h"]?.[1]) {
          setCorrectAnswer("ans8");
        }
        setQuestionType(data.question.question_type);
        setEditType("update");
        setThisQuestionId(questionid);
        setEditMode(true);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setLoadingQuestions(false);
    }
  }

  const handleNewQuestion = () => {
    setQuestionText("");
    setAnswerA("");
    setAnswerB("");
    setAnswerC("");
    setAnswerD("");
    setAnswerE("");
    setAnswerF("");
    setAnswerG("");
    setAnswerH("");
    setAnswerADetail("");
    setAnswerBDetail("");
    setAnswerCDetail("");
    setAnswerDDetail("");
    setAnswerEDetail("");
    setAnswerFDetail("");
    setAnswerGDetail("");
    setAnswerHDetail("");
    setCorrectAnswer("ans1");
    setQuestionType("");
    setQuestionWeight("");
    setThisQuestionId(0);
    setEditType("create");
    setEditMode(true);
    setCustomInput("");
    setCustomLabel("");
    setCompetition("");
  };

  const handleSubmit = (isPublished) => {
    if (!questionWeight || questionWeight === "") {
      setRequired("Weight is required");
      return;
    } else {
      setRequired("");
    }
    if (!questionType || questionType === "") {
      setRequired("Type is required");
      return;
    } else {
      setRequired("");
    }
    setUpdateError(false);
    setUpdating(true);
    updateQuestion(thisQuestionId, isPublished);
  };

  const handleCancel = () => {
    setEditMode(false);
  };

  const toggleDisable = (questionId) => {
    changeDisabled(questionId);
  };
  const handleEdit = (questionId) => {
    fetchQuestion(questionId);
  };

  useEffect(() => {
    fetchQuestions();
  }, []);

  return (
    <div id="content" className="min-h-[75vh] mt-[0px] md:mt-[40px] content-t">
      <div className="flex flex-row items-center justify-between">
        <div className="font-anton text-[30px] text-white my-[20px]">
          ADMIN - Wheel Trivia
        </div>
        <div className="font-chakra text-green text-[12px]">
          {questionUpdateMessage}
        </div>
      </div>

      {!editMode ? (
        <>
          <div className="w-full flex flex-row items-center gap-4">
            <div className="w-full flex flex-row items-end justify-between my-2">
              <div className="flex gap-2">
                <div
                  className="bg-lime flex justify-center items-center hover:bg-limehover text-main
                 w-[70px]  lg:w-[180px] h-[32px] text-center cursor-pointer"
                  onClick={() => handleNewQuestion()}
                >
                  ADD NEW
                </div>
                <div>
                  <input
                    type="text"
                    onChange={handleSearch}
                    value={searchValue}
                    placeholder="Search questions"
                    className="w-[90px] lg:w-full h-[32px] text-[12px] lg:text-[16px] p-1 text-main text-chakra"
                  />
                </div>
              </div>
              <div>
                {infoMessage && (
                  <p
                    className={`text-[10px] font-chakra mb-1 ${
                      loading
                        ? "text-white"
                        : infoMessage.includes("success")
                        ? "text-green"
                        : "text-red text-[14px]"
                    }`}
                  >
                    {infoMessage}
                  </p>
                )}
                {csvData && (
                  <p className="text-[10px] font-chakra mb-1">
                    {csvData?.name}
                  </p>
                )}
              </div>

              <div>
                <div className="flex flex-row gap-2 items-center">
                  <label
                    htmlFor="file-upload"
                    className="w-full lg:w-[180px] px-2 h-[32px] bg-white text-main text-[14px] font-chakra leading-[15px]
                  flex items-center justify-center cursor-pointer border border-main"
                    style={{ fontWeight: "bold" }}
                  >
                    Choose File
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    accept=".csv"
                    className="hidden"
                    onChange={handleFileUpload}
                  />
                  <button
                    className={`bg-lime px-2 hover:bg-limehover text-main text-[14px]
                    w-full lg:w-[180px] h-[32px] text-center cursor-pointer border-none font-bold
                                            ${
                                              !csvData || loading
                                                ? "opacity-50 cursor-not-allowed"
                                                : ""
                                            }`}
                    style={{ fontWeight: "bold" }}
                    onClick={uploadQuestions}
                    disabled={!csvData || loading}
                  >
                    UPLOAD CSV
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-row items-center gap-1 md:gap-2 mt-2">
            {isMobile ? (
              <select
                className="w-[80px] h-[40px] text-main text-[14px] font-[400] font-chakra leading-[15px] flex items-center justify-center cursor-pointer"
                value={queryFilters}
                onChange={(e) => setQueryFilters(e.target.value)}
              >
                {filtersOptions.map((item, index) => {
                  return <option key={index}>{item}</option>;
                })}
              </select>
            ) : (
              filtersOptions.map((item, index) => {
                const isActive = item === queryFilters;
                return (
                  <div
                    key={index}
                    onClick={() => setQueryFilters(item)}
                    className={`w-[100px] h-[40px] text-main text-[14px] font-chakra leading-[15px]
                              flex items-center justify-center cursor-pointer ${
                                isActive ? "bg-lime " : "bg-white"
                              }`}
                    style={{ fontWeight: "bold" }}
                  >
                    {item === "" ? "ALL" : item}
                  </div>
                );
              })
            )}
            {isMobile ? (
              <select
                className="w-[40px] h-[40px] text-main text-[14px] font-[400] font-chakra leading-[15px] flex items-center justify-center cursor-pointer"
                value={questionWeightFilter}
                onChange={(e) => setQuestionWeightFilter(e.target.value)}
              >
                <option></option>
                {questionFilters.map((item, index) => {
                  return <option key={index}>{item}</option>;
                })}
              </select>
            ) : (
              <>
                {questionFilters.map((item: number, index) => {
                  const isActive = item === questionWeightFilter;
                  return (
                    <div
                      key={index}
                      onClick={() => toggleUnselect(item)}
                      className={`w-[40px] h-[40px] text-main text-[14px] font-chakra leading-[15px]
                    flex items-center justify-center cursor-pointer ${
                      isActive ? "bg-lime " : "bg-white"
                    }`}
                      style={{ fontWeight: "bold" }}
                    >
                      {item}
                    </div>
                  );
                })}
              </>
            )}
            <div>
              <div className="flex gap-2">
                <div
                  className={`w-[60px] md:w-[100px] h-[40px] text-main text-[14px] font-chakra leading-[15px]
                  flex items-center justify-center cursor-pointer ${
                    isDisabledFilter === false ? "bg-lime" : "bg-white"
                  }`}
                  style={{ fontWeight: "bold" }}
                  onClick={() => toggleDisabled(false)}
                >
                  Live
                </div>
                <div
                  className={`w-[60px] md:w-[100px] h-[40px] text-main text-[14px] font-chakra leading-[15px]
                flex items-center justify-center cursor-pointer ${
                  isDisabledFilter === true ? "bg-lime" : "bg-white"
                }`}
                  style={{ fontWeight: "bold" }}
                  onClick={() => toggleDisabled(true)}
                >
                  Draft
                </div>
              </div>
            </div>
            <select
              className="w-[70px] md:w-[100px] h-[40px] text-main text-[14px] font-[400] font-chakra leading-[15px] flex items-center justify-center cursor-pointer"
              value={selectCompetition}
              onChange={(e) => setSelectCompetition(e.target.value)}
            >
              <option value="">Competition</option>
              {competitionList.map((item, index) => {
                return (
                  <option key={index} className="text-main">
                    {item}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mt-4">
            {loadingQuestions ? (
              <div className="font-chakra">Loading...</div>
            ) : (
              <>
                <div className="flex flex-row justify-between items-center">
                  <div>Count: {filteredQuestions.length}</div>
                  <div
                    className="w-[120px] h-[32px] bg-white text-main text-[14px] font-chakra leading-[15px]
                  flex items-center justify-center cursor-pointer"
                    style={{ fontWeight: "bold" }}
                    onClick={() => clearFilters()}
                  >
                    Clear Filters
                  </div>
                </div>
                {questionTypes.map((qtype, qti) => (
                  <div key={qti}>
                    <div className="flex flex-row items-center justify-between my-2"></div>
                    <div className="font-chakra">
                      {questionData.length > 0 &&
                        questionData.map((q: QuestionData) =>
                          q.question_type === qtype ? (
                            <div
                              key={q.id}
                              className="relative mb-4 p-5 pl-[45px] border-[1px] border-white border-solid flex"
                            >
                              <div className="flex-grow pr-[10px]">
                                <div className="absolute top-0 left-0 bg-lime text-center py-[5px] w-8 text-main font-anton">
                                  {q.id}
                                </div>
                                <div
                                  className="absolute flex justify-center items-center top-[27px] left-0 bg-white text-center py-[5px] w-8 text-main font-anton cursor-pointer"
                                  onClick={() => handleEdit(q.id)}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 576 512"
                                    width="16"
                                    height="16"
                                  >
                                    <path d="M402.6 83.2l90.2 90.2c3.8 3.8 3.8 10 0 13.8L274.4 405.6l-92.8 10.3c-12.4 1.4-22.9-9.1-21.5-21.5l10.3-92.8L388.8 83.2c3.8-3.8 10-3.8 13.8 0zm162-22.9l-48.8-48.8c-15.2-15.2-39.9-15.2-55.2 0l-35.4 35.4c-3.8 3.8-3.8 10 0 13.8l90.2 90.2c3.8 3.8 10 3.8 13.8 0l35.4-35.4c15.2-15.3 15.2-40 0-55.2zM384 346.2V448H64V128h229.8c3.2 0 6.2-1.3 8.5-3.5l40-40c7.6-7.6 2.2-20.5-8.5-20.5H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V306.2c0-10.7-12.9-16-20.5-8.5l-40 40c-2.2 2.3-3.5 5.3-3.5 8.5z" />
                                  </svg>
                                </div>
                                <div
                                  className="absolute top-[55px] left-0 bg-white text-center py-[5px] w-8 text-main font-anton cursor-pointer"
                                  onClick={() => toggleDisable(q.id)}
                                >
                                  {!q.disabled ? (
                                    <div className="w-4 h-4 rounded-full bg-green ml-2"></div>
                                  ) : (
                                    <div className="w-4 h-4 rounded-full bg-red ml-2"></div>
                                  )}
                                </div>
                                <div>
                                  <span className="font-bold mr-[10px]">
                                    QUESTION:
                                  </span>
                                  {q.question}
                                </div>
                                <div className="mt-[5px]">
                                  {q.answers &&
                                    Object.entries(q.answers).map(
                                      ([key, value]) => (
                                        <div
                                          key={key}
                                          className={`${
                                            value[1] ? "text-green" : "text-red"
                                          }`}
                                        >
                                          {key.toUpperCase()}: {value[0]}{" "}
                                          {value[2]}
                                        </div>
                                      )
                                    )}
                                </div>
                                <div className="mt-2">
                                  <p className="text-lime text-[10px]">
                                    {q.tags?.competition
                                      ? `TAGS | Competition: ${q.tags?.competition}`
                                      : ""}
                                  </p>
                                  <p className="mt-2 text-[10px]">
                                    Question weight: {q.question_weight}
                                  </p>
                                </div>
                              </div>
                              <div>
                                <span className="font-bold mr-[10px]">
                                  ANSWERED:
                                </span>
                                <div>
                                  <span className="mr-[10px]">COUNT:</span>
                                  {q.answered_count}
                                </div>
                                <div>
                                  <span className="mr-[10px]">CORRECTLY:</span>
                                  {q.answered_correct}
                                </div>
                              </div>
                            </div>
                          ) : null
                        )}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>

          <>
            <ReactPaginate
              breakLabel="..."
              nextLabel="→"
              onPageChange={handlePageClick}
              pageRangeDisplayed={5}
              pageCount={pageCount}
              previousLabel="←"
              containerClassName="flex items-center justify-center gap-2"
              pageClassName="w-[40px] h-[40px] bg-white text-main text-[14px] font-[400] font-chakra leading-[15px] flex items-center justify-center cursor-pointer"
              pageLinkClassName="text-sm text-gray-700"
              activeClassName="!bg-lime text-main"
              previousClassName="w-[40px] h-[40px] flex items-center justify-center bg-white cursor-pointer"
              previousLinkClassName="text-lg text-gray-700"
              nextClassName="w-[40px] h-[40px] flex items-center justify-center bg-white cursor-pointer"
              nextLinkClassName="text-lg text-gray-700"
              breakClassName="w-[40px] h-[40px] flex items-center justify-center"
              breakLinkClassName="text-sm text-gray-700"
            />
          </>
        </>
      ) : (
        <div className="mt-[40px]">
          <div className="flex font-chakra">
            <div className="w-[200px] pt-[2px] text-right pr-[10px]">
              QUESTION:
            </div>
            <div className="flex-grow">
              <input
                className="w-full text-main text-chakra"
                type="text"
                value={questionText}
                onChange={(e) => setQuestionText(e.target.value)}
              />
            </div>
          </div>
          <div className="flex font-chakra mt-[20px]">
            <div className="w-[200px] pt-[2px] text-right pr-[10px]">
              ANSWERS:
            </div>
            <div className="w-[20px] pt-[2px] pr-[10px]">A:</div>
            <div className="w-[400px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                value={answerA}
                onChange={(e) => setAnswerA(e.target.value)}
              />
            </div>
            <div className="w-[150px] pl-[20px]">
              <input
                type="radio"
                id="ans1"
                name="correctanswer"
                checked={correctAnswer === "ans1"}
                onChange={() => setCorrectAnswer("ans1")}
              />
              <label htmlFor="ans1">Correct</label>
            </div>
            <div className="w-[200px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                placeholder="[Answer detail]"
                value={answerADetail}
                onChange={(e) => setAnswerADetail(e.target.value)}
              />
            </div>
          </div>

          <div className="flex font-chakra mt-[10px]">
            <div className="w-[200px] pt-[2px] text-right pr-[10px]"></div>
            <div className="w-[20px] pt-[2px] pr-[10px]">B:</div>
            <div className="w-[400px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                value={answerB}
                onChange={(e) => setAnswerB(e.target.value)}
              />
            </div>
            <div className="w-[150px] pl-[20px]">
              <input
                type="radio"
                id="ans2"
                name="correctanswer"
                checked={correctAnswer === "ans2"}
                onChange={() => setCorrectAnswer("ans2")}
              />
              <label htmlFor="ans2">Correct</label>
            </div>
            <div className="w-[200px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                placeholder="[Answer detail]"
                value={answerBDetail}
                onChange={(e) => setAnswerBDetail(e.target.value)}
              />
            </div>
          </div>

          <div className="flex font-chakra mt-[10px]">
            <div className="w-[200px] pt-[2px] text-right pr-[10px]"></div>
            <div className="w-[20px] pt-[2px] pr-[10px]">C:</div>
            <div className="w-[400px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                value={answerC}
                onChange={(e) => setAnswerC(e.target.value)}
              />
            </div>
            <div className="w-[150px] pl-[20px]">
              <input
                type="radio"
                id="ans3"
                name="correctanswer"
                checked={correctAnswer === "ans3"}
                onChange={() => setCorrectAnswer("ans3")}
              />
              <label htmlFor="ans3">Correct</label>
            </div>
            <div className="w-[200px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                placeholder="[Answer detail]"
                value={answerCDetail}
                onChange={(e) => setAnswerCDetail(e.target.value)}
              />
            </div>
          </div>

          <div className="flex font-chakra mt-[10px]">
            <div className="w-[200px] pt-[2px] text-right pr-[10px]"></div>
            <div className="w-[20px] pt-[2px] pr-[10px]">D:</div>
            <div className="w-[400px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                value={answerD}
                onChange={(e) => setAnswerD(e.target.value)}
              />
            </div>
            <div className="w-[150px] pl-[20px]">
              <input
                type="radio"
                id="ans4"
                name="correctanswer"
                checked={correctAnswer === "ans4"}
                onChange={() => setCorrectAnswer("ans4")}
              />
              <label htmlFor="ans4">Correct</label>
            </div>
            <div className="w-[200px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                placeholder="[Answer detail]"
                value={answerDDetail}
                onChange={(e) => setAnswerDDetail(e.target.value)}
              />
            </div>
          </div>

          <div className="flex font-chakra mt-[10px]">
            <div className="w-[200px] pt-[2px] text-right pr-[10px]"></div>
            <div className="w-[20px] pt-[2px] pr-[10px]">E:</div>
            <div className="w-[400px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                value={answerE}
                onChange={(e) => setAnswerE(e.target.value)}
              />
            </div>
            <div className="w-[150px] pl-[20px]">
              <input
                type="radio"
                id="ans5"
                name="correctanswer"
                checked={correctAnswer === "ans5"}
                onChange={() => setCorrectAnswer("ans5")}
              />
              <label htmlFor="ans5">Correct</label>
            </div>
            <div className="w-[200px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                placeholder="[Answer detail]"
                value={answerEDetail}
                onChange={(e) => setAnswerEDetail(e.target.value)}
              />
            </div>
          </div>

          <div className="flex font-chakra mt-[10px]">
            <div className="w-[200px] pt-[2px] text-right pr-[10px]"></div>
            <div className="w-[20px] pt-[2px] pr-[10px]">F:</div>
            <div className="w-[400px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                value={answerF}
                onChange={(e) => setAnswerF(e.target.value)}
              />
            </div>
            <div className="w-[150px] pl-[20px]">
              <input
                type="radio"
                id="ans6"
                name="correctanswer"
                checked={correctAnswer === "ans6"}
                onChange={() => setCorrectAnswer("ans6")}
              />
              <label htmlFor="ans6">Correct</label>
            </div>
            <div className="w-[200px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                placeholder="[Answer detail]"
                value={answerFDetail}
                onChange={(e) => setAnswerFDetail(e.target.value)}
              />
            </div>
          </div>

          <div className="flex font-chakra mt-[10px]">
            <div className="w-[200px] pt-[2px] text-right pr-[10px]"></div>
            <div className="w-[20px] pt-[2px] pr-[10px]">G:</div>
            <div className="w-[400px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                value={answerG}
                onChange={(e) => setAnswerG(e.target.value)}
              />
            </div>
            <div className="w-[150px] pl-[20px]">
              <input
                type="radio"
                id="ans7"
                name="correctanswer"
                checked={correctAnswer === "ans7"}
                onChange={() => setCorrectAnswer("ans7")}
              />
              <label htmlFor="ans7">Correct</label>
            </div>
            <div className="w-[200px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                placeholder="[Answer detail]"
                value={answerGDetail}
                onChange={(e) => setAnswerGDetail(e.target.value)}
              />
            </div>
          </div>

          <div className="flex font-chakra mt-[10px]">
            <div className="w-[200px] pt-[2px] text-right pr-[10px]"></div>
            <div className="w-[20px] pt-[2px] pr-[10px]">H:</div>
            <div className="w-[400px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                value={answerH}
                onChange={(e) => setAnswerH(e.target.value)}
              />
            </div>
            <div className="w-[150px] pl-[20px]">
              <input
                type="radio"
                id="ans8"
                name="correctanswer"
                checked={correctAnswer === "ans8"}
                onChange={() => setCorrectAnswer("ans8")}
              />
              <label htmlFor="ans8">Correct</label>
            </div>
            <div className="w-[200px]">
              <input
                className="w-full text-main text-chakra"
                type="text"
                placeholder="[Answer detail]"
                value={answerHDetail}
                onChange={(e) => setAnswerHDetail(e.target.value)}
              />
            </div>
          </div>
          <div>
            <div className="flex flex-col font-chakra mt-[20px]">
              <div className="flex flex-row">
                <div className="w-[200px] pt-[2px] text-right pr-[10px] mb-2 font-[800]">
                  Tags
                </div>
              </div>
              <div className="flex flex-row">
                <div className="w-[200px] pt-[2px] text-right pr-[10px] mb-4">
                  Competition:
                </div>
                <div className="w-[400px]">
                  <input
                    className="w-full text-main text-chakra"
                    type="text"
                    value={competition}
                    onChange={(e) => setCompetition(e.target.value)}
                    list="competition"
                    placeholder="Search Competition..."
                  />
                  <datalist className="w-full text-chakra" id="competition">
                    <option value="">Competition</option>
                    {competitionList.map((item) => {
                      return <option className="text-main">{item}</option>;
                    })}
                  </datalist>
                </div>
              </div>
              <div className="flex flex-col md:flex-row">
                <div className="flex flex-row">
                  <div className="w-[200px] pt-[2px] text-right pr-[10px] mb-4">
                    Custom Tag:
                  </div>
                  <div className="w-[400px]">
                    <input
                      className="w-full text-main text-chakra"
                      type="text"
                      value={customLabel}
                      onChange={(e) =>
                        setCustomLabel(e.target.value.toLowerCase())
                      }
                      placeholder="Key"
                    />
                  </div>
                </div>
                <div className="flex flex-row">
                  <div className="w-[200px] pt-[2px] text-right pr-[10px] mb-4 md:w-[0]"></div>
                  <div className="w-[400px]">
                    <input
                      className="w-full text-main text-chakra"
                      type="text"
                      placeholder="Value"
                      value={customInput}
                      onChange={(e) => setCustomInput(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full flex flex-col md:flex-row items-start md:items-center">
            <div className="flex font-chakra mt-[20px]">
              <div className="md:w-[200px] pt-[2px] text-right pr-[10px]">
                QUESTION TYPE:
              </div>
              <div className="text-main">
                <select
                  value={questionType}
                  onChange={(e) => {
                    if (e.target.value) {
                      setRequired("");
                    }
                    setQuestionType(e.target.value);
                  }}
                  required
                >
                  <option></option>
                  <option value="home">Home</option>
                  <option value="away">Away</option>
                  <option value="general">General</option>
                </select>
              </div>
            </div>

            <div className="flex font-chakra mt-[20px]">
              <div className="md:w-[200px] pt-[2px] text-right pr-[10px]">
                QUESTION Weight:
              </div>
              <div className="text-main">
                <select
                  value={questionWeight}
                  onChange={(e) => {
                    if (e.target.value) {
                      setRequired("");
                    }
                    setQuestionWeight(e.target.value);
                  }}
                  required
                >
                  <option></option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">6</option>
                  <option value="7">7</option>
                  <option value="8">8</option>
                  <option value="9">9</option>
                  <option value="10">10</option>
                </select>
              </div>
              <div className="flex justify-center items-center">
                {required ? (
                  <p className="text-[10px] text-red">{required}</p>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="mt-[30px]">
            {!updating ? (
              <div className="flex flex-col md:flex-row">
                <div className="flex flex-row items-center gap-2">
                  <div
                    className="bg-lime flex justify-center items-center hover:bg-limehover text-main w-[180px] h-[32px] text-center cursor-pointer border-none"
                    onClick={() => handleSubmit(false)}
                  >
                    Save & Publish
                  </div>
                  <div
                    className="bg-main flex justify-center items-center hover:bg-mainB text-lime w-[180px] h-[32px] text-center cursor-pointer border-none"
                    onClick={() => handleSubmit(true)}
                  >
                    Save to Draft
                  </div>
                  <div
                    className="border-2 flex justify-center items-center border-lime hover:border-limehover border-solid text-lime hover:text-limehover w-[180px] h-[32px] text-center cursor-pointer"
                    onClick={handleCancel}
                  >
                    Cancel
                  </div>
                </div>
              </div>
            ) : (
              <div className="text-chakra text-white">Updating...</div>
            )}
          </div>
          {updateError && (
            <div className="mt-[10px] text-red">Error updating question.</div>
          )}
        </div>
      )}
    </div>
  );
};
export default Admin;
